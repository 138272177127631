<template>
  <search-form :items="items" @fnSearch="handleFnSearch" />
  <grid
    ref="gridRef"
    allow-selection
    :columns="columns"
    :code="$route.path"
    :url="url"
    :search="search"
    :btn-operation="btnOperation"
    :scroll="{ x: 2000 }"
  >
    <template #operation="{ record }">
      <operation :options="options" :record="record" />
    </template>
  </grid>
</template>

<script>
import { reactive, ref, toRefs, h } from 'vue';
import { message, Modal } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import { downloadExcel } from '@/utils';
import contactApi from '@/api/contact.js';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      search: {},
    });
    const getUploadOption = url => ({
      name: 'multipartfile',
      accept: '.xlc,.xlm,.xls,.xlsx,.xlt,.xlw',
      action:
        process.env.VUE_APP_API_SERVER + process.env.VUE_APP_API_BASE_URL + url,
      onChange: ({ file }) => {
        if (file.status === 'done') {
          const { count, wrongSize, error, result } = file.response.data;
          if (result) {
            Modal.info({
              title: `共导入 ${count} 条数据，其中：`,
              content: h('div', {}, [
                h(
                  'p',
                  { style: 'color: green' },
                  `成功导入 ${count - wrongSize} 条数据！`
                ),
                wrongSize
                  ? h(
                      'p',
                      { style: 'color: red' },
                      `失败 ${wrongSize} 条数据：${error}`
                    )
                  : '',
              ]),
            });
            gridRef.value.refreshGrid();
          } else {
            message.error('导入失败！');
          }
        } else if (file.status === 'error') {
          // message.error(`${info.file.name} file upload failed.`);
        }
      },
    });

    return {
      gridRef,
      ...toRefs(state),
      items: [
        { key: 'fullName', label: '姓名' },
        { key: 'company', label: '公司' },
        { key: 'position', label: '职位' },
        { key: 'industryLv1Code', label: '行业' },
        { key: 'province', label: '省份' },
        { key: 'city', label: '城市' },
      ],
      columns: [
        { dataIndex: 'fullName', title: '姓名', width: 150 },
        { dataIndex: 'mobileNum', title: '手机号', width: 130 },
        { dataIndex: 'email', title: '邮箱' },
        { dataIndex: 'company', title: '公司' },
        { dataIndex: 'position', title: '职位' },
        { dataIndex: 'industryLv1Code', title: '行业' },
        { dataIndex: 'department', title: '部门' },
        { dataIndex: 'province', title: '省份' },
        { dataIndex: 'city', title: '城市' },
        { dataIndex: 'unionId', title: '微信ID' },
        // {
        //   key: 'id',
        //   title: '操作',
        //   fixed: 'right',
        //   width: 50,
        //   slots: { customRender: 'operation' },
        // },
      ],
      btnOperation: [
        {
          type: 'download',
          label: '联系人模版下载',
          icon: 'DownloadOutlined',
          permission: 'contact:download',
          fnClick: () =>
            contactApi
              .downloadContactTemplate('contact:download')
              .then(({ name, data }) => downloadExcel(name, data)),
        },
        {
          type: 'import',
          label: '联系人导入',
          icon: 'ImportOutlined',
          permission: 'contact:import',
          upload: getUploadOption(contactApi.importContactUrl),
        },
        {
          type: 'download1',
          label: '行为模版下载',
          icon: 'DownloadOutlined',
          permission: 'contact:download1',
          fnClick: () => {
            contactApi
              .downloadActionTemplate('contact:download1')
              .then(({ name, data }) => downloadExcel(name, data));
          },
        },
        {
          type: 'import1',
          label: '行为导入',
          icon: 'ImportOutlined',
          permission: 'contact:import1',
          upload: getUploadOption(contactApi.importActionUrl),
        },
        {
          type: 'export',
          label: '导出',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: ids => {
            if (ids.length) {
              contactApi
                .export('contact:export', { ids })
                .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
      options: [
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'contact:view',
          fnClick: record => {
            console.log(record);
          },
        },
      ],
      url: contactApi.pageUrl,
      handleFnSearch: value => (state.search = value),
    };
  },
};
</script>

<style lang="less" scoped>
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
